@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh; 
  padding: 1rem 0.5rem;
  min-height: 100vh;
  
}

section {
    width: 100%;
    max-width: 420px;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem;
    background-color: rgba(0,0,0,0.4);
}

section h1{
    color: #fff;
}

form {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-grow: 1;
    padding-bottom: 1rem;
}

a, a:visited {
    color: #fff;
}

input[type="text"],
input[type="password"],
button,
textarea {
  font-family: 'Nunito', sans-serif;
  font-size: 22px;
  padding: 0.25rem;
  border-radius: 0.5rem;
}

label{
    font-weight: bold;
}

label,
button {
  margin-top: 1rem;
}

button {
  padding: 0.5rem;
}

.instructions {
    font-size: 0.75rem;
    border-radius: 0.5rem;
    background: #000;
    color: #fff;
    padding: 0.25rem;
    position: relative;
    bottom: -10px;
}

.instructions > svg {
    margin-right: 0.25rem;
}

.offscreen {
    position: absolute;
    left: -9999px;
}

.hide {
    display: none;
}

.valid {
    color: limegreen;
    margin-left: 0.25rem;
}

.invalid {
    color: red;
    margin-left: 0.25rem;
}

.errmsg {
    background-color: lightpink;
    color: firebrick;
    font-weight: bold;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
}

.line {
    display: inline-block;
}

.containerr{
    width: 99vw;
    min-height: 100vh;
    background: #ced8ff;
    transition: 0.5s;
  }
  
  .containerr.dark{
    background: #222;
  }
  
  .navbar{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    padding: 15px 7%;
    transition: 0.5s
  }
  
  .logo{
    width: 70px;
    cursor: pointer;
  }
  
  ul{
    flex: 1;
    list-style: none;
    text-align: center;
  }
  
  ul li{
    display: inline-block;
    margin: 10px 20px;
    font-size: 18px;
    cursor: pointer;
  }
  
  ul li:hover{
    color:#ced8ff;
    transition: 0.2s ease-in-out;
  }
  
  .toggle-icon{
    width: 44px;
    cursor: pointer;
    margin-left: 40px;
  }
  
  .search-box{
    display: flex;
    align-items: center;
    background: #242424;
    padding: 10px 20px;
    border-radius: 50px;
  }
  
  .search-box input{
    padding: 6px;
    background: transparent;
    border: 0;
    outline: 0;
    color: #fff;
    font-size: 16px;
    max-width: 200px;
  }
  
  .search-box input::placeholder{
    color: #fff;
  }
  
  .search-box img{
    width: 25px;
    cursor: pointer;
  }
  
  .dark .navbar{
    background: #000;
    color: #fff;
  }
  
  .dark .search-box{
    background: #fff;
  }
  
  .dark .search-box input{
    color: #222;
  }
  
  .dark .search-box input::placeholder{
    color: #222;
  }
  
  .location{
    background-image: url(../src/Assest/maps-bg.jpg);
    background-size: cover;
    background-position: center;
    height: 40vh;
  }
  
  .firstSec-text{
    width: 86%;
    margin: 0 auto;
    padding-top: 60px;
  }
  
  .firstSec-text h1{
    color: #2A313D;
    font-size: 25px;
  }
  
  .firstSec-text span{
    color: #fff;
    font-size: 50px;
  }
  
  .firstSec-text p {
    padding-top: 15px;
    letter-spacing: 1.6px;
    font-size: 14px;
    color: #2A313D;
  }
  
  .location-main{
    padding-top: 20px;
  }
  
  .location-main h3{
    color: #2A313D;
  }
  
  .form-area input{
    border: none;
    border: 2px solid #ced8ff;
    border-radius: 4px;
    height: 4vh;
    margin-top: 10px;
  }
  
  .form-area .loca-btn{
    width: 100px;
    height: 30px;
    background: none;
    border: none;
    border: 2px solid #fff;
    margin-top: 10px;
    font-weight: bold;
    transition: 0.3s;
    border-radius: 4px;
    font-size: 17px;
    padding-bottom: 1.6rem;
  }
  
  .form-area .loca-btn:hover{
    border: 2px solid #2A313D;
    color: #fff;
  }
  
  .location-cont{
    width: 86%;
    margin: 0 auto;
    display: flex;
    gap: 50%;
  }
  
  .result{
    background: #000;
    position: relative;
    bottom: 20px;
  }
  
  table{
    border: 1px solid #bdc3c7;
    width: 350px;
    height: 17vh;
    box-shadow: 2px 2px 12px rgba(0,0,0,0.2), -1px -1px 8px rgba(0,0,0,0);
  }
  
  tr{
    transition: all .2s ease-in;
    cursor: pointer;
  }
  
  th{
    border-bottom: 1px solid #2A313D;
    font-weight: bold;
  }
  
  th,
  td{
    padding: 12px;
    text-align: left;
  }
  
  td{
    color:#fff;
  }
  
  tr:hover{
    transform: scale(1.02);
    box-shadow: 2px 2px 12px rgba(0,0,0,0.2), -1px -1px 8px rgba(0,0,0,0);
  }
  
  .headerr{
    color: #ced8ff;
    height: 6vh;
  }
  
  .reviews h1{
    text-align: center;
    font-size: 22px;
    padding-top: 40px;
  }
  
  .reviews span{
    color: #fff;
    font-size: 27px;
  }
  
  .reviews-cont{
    width: 86%;
    margin: 0 auto;
    display: flex;
    gap: 50px;
    padding-top: 50px;
  }
  
  .reviews-cont div{
    background: #fff;
    width: 35%;
    border-radius: 15px;
    padding: 30px 30px ;
    font-size: 13px;
    box-shadow: 1px 3px 7px rgba(0,0,0,0.85);
  }
  
  .reviews-cont div h3{
    color:#2A313D;
  }
  
  .reviews-cont div h5{
    color:#ced8ff;
  }
  
  .reviews-cont div p{
    padding-top: 12px;
    color: #656765;
  } 
  
  .bottom{
    background: black;
    color: #fff;
    margin-top: 50px;
    height: 14.3vh;
  }
  
  .bottom-cont{
    width: 86%;
    margin: 0 auto;
    display: flex;
    gap: 23%;
    padding-top: 20px; 
    color: #656765;
  }
  
  .bottom-cont h4 {
    margin-bottom: 7px;
    color: #fff;
  }
  
  .geomap{
    background: #fff;
    width: 100%;
    height: 40vh;
  }
  
  .geomap h1{
    color:#000;
    text-align: center;
    letter-spacing: 1.2px;
    padding-top: 40px;
  }
  
  .Geo-tab{
    margin: 0 auto;
    margin-top: 14px;
    border: 1px solid #bdc3c7;
    width: 800px;
    height: 17vh;
    box-shadow: 2px 2px 12px rgba(0,0,0,0.2), -1px -1px 8px rgba(0,0,0,0);
  }
  
  .Geo-header th{
    color:#ced8ff;
  }
  
  .Geo-tab td{
    color:#2A313D;
    font-weight: bold;
  }
  
  .Geo-tab p{
    font-weight: bold;
    color:#2A313D;
  }

  /* mobile screens resolution(iphone6/7/8) 375 x 667 */
  @media screen and (max-width: 375px) and (height: 667px) {
    .search-box{
      display: none;
    }
    
    .firstSec-text span{
      font-size: 22px;
    }
    .firstSec-text h1{
      font-size: 13px;
    }
    .firstSec-text p{
      font-size: 10px !important;
    }
    .form-area .loca-btn{
      font-size: 12px;
      padding-bottom: 1.4rem;
      margin-bottom: 40px;
    }
    .location{
      height: 73vh !important;
    }
    .location-cont{
      display: flex;
      flex-direction: column;
    }
    .result .tab{
      width: 100% !important;
      font-size: 10px;
    }

    table{
      width: 100% !important;
    }
    
    .reviews-cont{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .reviews-cont div{
      width: 100%;
    }

    .bottom{
      height: 40vh;
    }
    .bottom-cont{
      display: flex;
      flex-direction: column;
      gap: 50px;
    }
  }

  /* mobile screens resolution(iphone6/7/8 +) 414 x 736 */
  @media screen and (max-width: 414px) and (height: 736px) {
    .search-box{
      display: none;
    }
    
    .firstSec-text span{
      font-size: 32px;
    }
    .firstSec-text h1{
      font-size: 13px;
    }
    .firstSec-text p{
      font-size: 13px;
    }
    .form-area .loca-btn{
      font-size: 12px;
      padding-bottom: 1.5rem;
      margin-bottom: 40px;
    }
    .location{
      height: 63vh !important;
    }
    .location-cont{
      display: flex;
      flex-direction: column;
    }
    .result .tab{
      width: 100% !important;
      font-size: 10px;
    }

    table{
      width: 100% !important;
    }
    
    .reviews-cont{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .reviews-cont div{
      width: 100%;
    }

    .bottom{
      height: 40vh;
    }
    .bottom-cont{
      display: flex;
      flex-direction: column;
      gap: 50px;
    }
  }

  /* mobile screens resolution(iphoneXR) 414 x 896 */
  @media screen and (max-width: 414px) and (max-height: 896px) {
    .search-box{
      display: none;
    }
    
    .firstSec-text span{
      font-size: 32px;
    }
    .firstSec-text h1{
      font-size: 13px;
    }
    .firstSec-text p{
      font-size: 13px;
    }
    .form-area .loca-btn{
      font-size: 12px;
      padding-bottom: 1.5rem;
      margin-bottom: 40px;
    }
    .location{
      height: 63vh;
    }
    .location-cont{
      display: flex;
      flex-direction: column;
    }
    .result .tab{
      width: 100% !important;
      font-size: 10px;
    }

    table{
      width: 100% !important;
    }
    
    .reviews-cont{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .reviews-cont div{
      width: 100%;
    }

    .bottom{
      height: 40vh;
    }
    .bottom-cont{
      display: flex;
      flex-direction: column;
      gap: 50px;
    }
  }

  /* mobile screens resolution(iphone12pro) 390 x 844 */
  @media screen and (max-width: 390px) and (max-height: 844px) {
    .search-box{
      display: none;
    }
    
    .firstSec-text span{
      font-size: 32px;
    }
    .firstSec-text h1{
      font-size: 13px;
    }
    .firstSec-text p{
      font-size: 13px;
    }
    .form-area .loca-btn{
      font-size: 12px;
      padding-bottom: 1.5rem;
      margin-bottom: 40px;
    }
    .location{
      height: 63vh;
    }
    .location-cont{
      display: flex;
      flex-direction: column;
    }
    .result .tab{
      width: 100% !important;
      font-size: 10px;
    }

    table{
      width: 100% !important;
    }
    
    .reviews-cont{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .reviews-cont div{
      width: 100%;
    }

    .bottom{
      height: 40vh;
    }
    .bottom-cont{
      display: flex;
      flex-direction: column;
      gap: 50px;
    }
  }

   /* mobile screens resolution(iphone14promax) 430 x 932 */
   @media screen and (max-width: 430px) and (max-height: 932px) {
    .search-box{
      display: none;
    }
    
    .firstSec-text span{
      font-size: 32px;
    }
    .firstSec-text h1{
      font-size: 13px;
    }
    .firstSec-text p{
      font-size: 13px;
    }
    .form-area .loca-btn{
      font-size: 12px;
      padding-bottom: 1.5rem;
      margin-bottom: 40px;
    }
    .location{
      height: 57vh;
    }
    .location-cont{
      display: flex;
      flex-direction: column;
    }
    .result .tab{
      width: 100% !important;
      font-size: 10px;
    }

    table{
      width: 100% !important;
    }
    
    .reviews-cont{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .reviews-cont div{
      width: 100%;
    }

    .bottom{
      height: 40vh;
    }
    .bottom-cont{
      display: flex;
      flex-direction: column;
      gap: 50px;
    }
  }

  /* mobile screens resolution(pixel7) 412 x 915 */
  @media screen and (max-width: 412px) and (max-height: 915px) {
    .search-box{
      display: none;
    }
    
    .firstSec-text span{
      font-size: 32px;
    }
    .firstSec-text h1{
      font-size: 13px;
    }
    .firstSec-text p{
      font-size: 13px;
    }
    .form-area .loca-btn{
      font-size: 12px;
      padding-bottom: 1.5rem;
      margin-bottom: 40px;
    }
    .location{
      height: 57vh;
    }
    .location-cont{
      display: flex;
      flex-direction: column;
    }
    .result .tab{
      width: 100% !important;
      font-size: 10px;
    }

    table{
      width: 100% !important;
    }
    
    .reviews-cont{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .reviews-cont div{
      width: 100%;
    }

    .bottom{
      height: 40vh;
    }
    .bottom-cont{
      display: flex;
      flex-direction: column;
      gap: 50px;
    }
  }

  /* mobile screens resolution(samsung galaxy s8+) 360 x 740 */
  @media screen and (max-width: 360px) and (max-height: 740px) {
    .search-box{
      display: none;
    }
    
    .firstSec-text span{
      font-size: 32px;
    }
    .firstSec-text h1{
      font-size: 13px;
    }
    .firstSec-text p{
      font-size: 10px;
    }
    .form-area .loca-btn{
      font-size: 12px;
      padding-bottom: 1.5rem;
      margin-bottom: 40px;
    }
    .location{
      height: 65vh;
    }
    .location-cont{
      display: flex;
      flex-direction: column;
    }
    .result .tab{
      width: 100% !important;
      font-size: 9px;
    }

    table{
      width: 100% !important;
    }
    
    .reviews-cont{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .reviews-cont div{
      width: 100%;
    }

    .bottom{
      height: 40vh;
    }
    .bottom-cont{
      display: flex;
      flex-direction: column;
      gap: 50px;
    }
  }